<template>
    <div id="detection-page" class="c pt-3 mb-6">
        <div class="card bg-info text-white form-group mt-5" v-if="loading">
            <img :src="`${getImagePath()}confirmation.png`" class="card-img-top" alt="..."/>
            <div class="card-body text-center">
                <h5 class="m-0">
                    Détection des clients autour de vous...<br/>
                    <strong>Veuillez patienter.</strong>
                </h5>
            </div>
        </div>
        <div class="card bg-info text-white form-group mt-5" v-else>
            <img :src="`${getImagePath()}confirmation-1.png`" class="card-img-top" alt="..."/>
            <div v-if="mode === 'standard'">
                <div class="card-body text-center" v-if="client">
                    <h5 class="m-0">
                        Êtes-vous chez<br/>
                        <strong id="clientName">
                            {{ client.customer_lastname }}
                            {{ client.customer_firstname }},
                        </strong>
                        <br/>
                        {{ client.adress1 }}, {{ client.postal_code }} {{ client.city }} ?
                    </h5>
                </div>
                <div class="card-body text-center" v-else>
                    <h5 class="m-0">
                        Pas de client détecté
                    </h5>
                </div>

                <form class="form-group container-fluid" action="">
                    <div class="row" v-if="client">
                        <div class="col">
                            <router-link to="/clients?selectMode=detection"
                                         class="btn btn btn-outline-primary w-100 w-100">NON,<br/>changer.
                            </router-link>
                        </div>
                        <div class="col">
                            <div v-if="!queryPending" @click="validate" class="btn btn-success w-100">
                                OUI,<br/>c'est exact.
                            </div>
                            <div v-else
                                 class="btn btn-success w-100 h-100 d-flex align-items-center justify-content-center">
                                <b-spinner type="grow" label="Spinning"></b-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col">
                            <router-link to="/clients?selectMode=detection"
                                         class="btn btn btn-outline-primary w-100 w-100">Chercher le client
                            </router-link>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else>
                <div class="card-body text-center" v-if="client">
                    <h5 class="m-0">
                        Détection des clients autour de vous...<br/>
                        <strong>Veuillez patienter.</strong>
                    </h5>
                </div>
            </div>
            <!-- <div v-if="mode === 'userContracts'">
                <div class="card-body text-center" v-if="client">
                    <h5 class="m-0">
                        Veuillez choisir votre contrats :
                    </h5>
                </div>
                <b-form class="p-4">
                    <b-form-group
                            label-cols="5"
                            label="Contrats"
                    >
                        <b-form-select :options="userContracts" v-model="params.employee_contract_idemployee_contract"></b-form-select>
                    </b-form-group>
                </b-form>
                <div class="col p-2 text-center">
                    <b-btn @click="getClientContracts" class="btn btn-success">
                        Confirmer
                    </b-btn>
                </div>
            </div>
            <div v-if="mode === 'clientContracts'">
                <div class="card-body text-center" v-if="client">
                    <h5 class="m-0">
                        Veuillez choisir le contrat du client :
                    </h5>
                </div>
                <b-form class="p-4">
                    <b-form-group
                            label-cols="5"
                            label="Contrats"
                    >
                        <b-form-select :options="clientContracts" v-model="params.contract_idcontract"></b-form-select>
                    </b-form-group>
                </b-form>
                <div class="col p-2 text-center">
                    <b-btn @click="declarerArrivee" class="btn btn-success">
                        Confirmer
                    </b-btn>
                </div>
            </div> -->
        </div>
        <b-modal ref="confirm-position" id="confirmPositionModal" no-close-on-backdrop no-close-on-esc>
            <template v-slot:modal-header>
                <div class="modal-title text-center w-100 bg-info text-white p-3">
                    <span class="font-weight-bold m-0 h4">Vous semblez éloigné du domicile du client</span>
                </div>
            </template>
            <template v-slot:default>
                <b-container fluid>
                    <b-row>
                        <b-col sm="12" class="text-justify">
                            Pour corriger le positionnement du lieu d'intervention, votre localisation va être
                            enregistrée.
                        </b-col>
                    </b-row>
                </b-container>
            </template>
            <template v-slot:modal-footer>
                <div class="row p-0 m-0 w-100">
                    <div class="col-6 p-0 m-0">
                        <button type="submit"
                                class="btn btn-lg btn-secondary w-100 m-0 no-top-border-radius small"
                                @click="hideConfirmPosition">
                            <small>Je ne suis pas encore arrivé</small>
                        </button>
                    </div>
                    <div class="col-6 p-0 m-0">
                        <button v-if="!queryPending" type="submit"
                                class="btn btn-lg btn-success m-0 no-top-border-radius"
                                @click="createPointage(params.latitude, params.longitude, params.accuracy, params.distance, true)">
                            <small>Je suis bien chez mon client</small>
                        </button>
                        <button v-else type="submit"
                                class="btn btn-lg btn-success m-0 no-top-border-radius w-100 h-100">
                            <b-spinner type="grow" label="Spinning"></b-spinner>
                        </button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import router from '../../router'
import clientService from '../../services/clientService'
import pointageService from "../../services/pointageService";
import userService from "../../services/userService";

export default {
    name: "Detection",
    data() {
        return {
            loading: true,
            client: null,
            mode: 'standard',
            clientContracts: [],
            userContracts: [],
            params: {},
            queryPending: false,
        }
    },
    mounted() {
        this.queryPending = false;
        if (router.currentRoute.query.clientId) {
            clientService.getClient(router.currentRoute.query.clientId, router.currentRoute.query.intervadress).then((response) => {
                if (response.data.etat === 'OK') {
                    this.client = response.data.data
                } else {
                    this.$rollbar.error('Erreur 3026');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3026',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
                this.loading = false
            }).catch((error) => {
                this.$rollbar.error('Erreur 3027', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3027',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
                this.loading = false
            })
        } else {
            let params = {
                latitude: 0,
                longitude: 0,
                accuracy: 0,
                distance: 0
            }
            navigator.geolocation.getCurrentPosition((pos) => {
                params.latitude = pos.coords.latitude
                params.longitude = pos.coords.longitude
                params.accuracy = pos.coords.accuracy;

                clientService.getClients(params).then((response) => {
                    if (response.data.etat === 'OK') {
                        this.client = response.data?.data?.[0]
                    } else {
                        this.$rollbar.error('Erreur 3028');
                        this.$bvToast.toast('Une erreur est survenue', {
                            title: 'Erreur 3028',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                    this.loading = false
                }).catch((error) => {
                    this.$rollbar.error('Erreur 3029', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3029',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                    this.loading = false
                })
            }, () => {
                // this.$bvToast.toast('Une erreur est survenue', {
                //     title: 'Erreur 3030',
                //     autoHideDelay: 5000,
                //     variant: 'danger'
                // })
                this.loading = false
            }, {
                enableHighAccuracy: true,
                maximumAge: 0
            })
        }
    },
    methods: {
        getImagePath() {
            if (!location.host.includes('family')) {
                return "/img/";
            } else {
                return "/img/gfs_"
            }
        },
        validate() {
            this.queryPending = true;
            // get user contracts
            userService.guessContract(this.client.customer_idcustomer).then((result) => {
                if (result.data.etat === 'OK') {
                    this.queryPending = false;
                    if (Array.isArray(result.data.data) && result.data.data.length >= 1) {
                        this.mode = 'userContracts'
                        this.userContracts = result.data.data.map((item) => {
                            return {
                                value: item.idemployee_contract,
                                text: item.contract_code + ' - ' + item.branch_name
                            }
                        })
                        this.params['employee_contract_idemployee_contract'] = this.userContracts[0].value
                        if (result.data.data.length == 1)
                            this.getClientContracts()
                    } else {
                        this.params['employee_contract_idemployee_contract'] = result.data.data.idemployee_contract
                        this.getClientContracts()
                    }
                } else {
                    this.queryPending = false;
                    this.$rollbar.error('Erreur 3031');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3031',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3032', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3032',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getClientContracts() {
            this.queryPending = true;
            clientService.getClientContractsByEmployeeContract(this.params.employee_contract_idemployee_contract, this.client.customer_idcustomer).then((response) => {
                if (response.data.etat === 'OK') {
                    this.queryPending = false;
                    let contract = response.data.data.find(e => e.idcontract === this.client.idcontract)
                    if (!contract) contract = response.data.data[0]
                    this.params['contract_idcontract'] = contract.idcontract;
                    this.declarerArrivee(contract.idcontract, contract.name);
                } else {
                    this.$rollbar.error('Erreur 3033');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3033',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3034', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3034',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        createPointage(latitude = null, longitude = null, accuracy = 0, distance = 0, tooFar = false) {
            this.queryPending = true;
            this.params['intervention_date'] = this.$moment().format('YYYY-MM-DD')
            this.params['start_time_real'] = this.$moment().format('HH:mm:ss')
            this.params['latitude'] = latitude
            this.params['longitude'] = longitude

            pointageService
                .declarerArrivee(this.params)
                .then((result) => {
                    if (result.data.etat === 'OK') {
                        if (tooFar) pointageService.createDistanceError({
                            idIntervention: result.data.data.data,
                            latitude: latitude,
                            longitude: longitude,
                            accuracy: accuracy,
                            distance: distance
                        });

                        router.push({
                            name: 'intervention',
                            params: {idIntervention: result.data.data.data}
                        })
                    }
                })
                .catch((error) => {
                    this.queryPending = false;
                    this.$rollbar.error('Erreur 3035', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3035',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                })
        },
        declarerArrivee(idcontract, activityName = null) {
            if (activityName.includes('enfant')) return this.createPointage();
            navigator.geolocation.getCurrentPosition((pos) => {
                const accuracy = (pos.coords.accuracy ?? 0);
                return pointageService
                    .getDistance({
                        latitude: pos.coords.latitude,
                        longitude: pos.coords.longitude,
                        idcontract: idcontract,
                        accuracy
                    })
                    .then(res => {
                        let tooFar = false;

                        if (Object.prototype.hasOwnProperty.call(res.data?.data, 'tooFar')) {
                            if (res.data.data.tooFar) tooFar = true;
                        } else if (Math.max(0, res.data?.data?.distance * 1000 - accuracy) > 2000) {
                            tooFar = true;
                        }

                        if (tooFar) {
                            this.params.latitude = pos.coords.latitude;
                            this.params.longitude = pos.coords.longitude;
                            this.params.accuracy = pos.coords.accuracy;
                            this.params.distance = res.data.data.distance * 1000;
                            this.$refs['confirm-position'].show();
                            this.queryPending = false;
                        } else {
                            this.createPointage(
                                pos.coords.latitude,
                                pos.coords.longitude
                            );
                        }
                    })
                    .catch(() => {
                        this.createPointage();
                        // console.log(error)
                        // this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        //     title: 'Erreur 3036',
                        //     autoHideDelay: 5000,
                        //     variant: 'danger'
                        // })
                    })
            }, () => {
                this.createPointage();
                // console.log(error);
                // this.$bvToast.toast('Impossible de récupérer votre géolocalisation, vérifiez que votre gps est activé et que vous avez autorisé le site à accéder à votre position.', {
                //     title: 'Erreur 3037',
                //     autoHideDelay: 5000,
                //     variant: 'danger'
                // })
            }, {
                enableHighAccuracy: true,
                maximumAge: 0
            })
        },
        hideConfirmPosition() {
            this.queryPending = false;
            this.$refs['confirm-position'].hide();
        }
    }
}
</script>

<style scoped>
/deep/ #confirmPositionModal .modal-header,
/deep/ #confirmPositionModal .modal-footer,
/deep/ #confirmPositionModal .container-fluid {
    padding: 0;
}
</style>
