import { render, staticRenderFns } from "./Detection.vue?vue&type=template&id=18fc1982&scoped=true&"
import script from "./Detection.vue?vue&type=script&lang=js&"
export * from "./Detection.vue?vue&type=script&lang=js&"
import style0 from "./Detection.vue?vue&type=style&index=0&id=18fc1982&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fc1982",
  null
  
)

export default component.exports